import React,{lazy,Suspense}  from 'react';
// import Skills from '../pages/Skills';
// import AboutMe from '../pages/AboutMe';
// import Portfolio from '../pages/Portfolio';
// import ContactMe from '../pages/ContactMe';
import Loader1 from './Loader1';
const Skills= lazy(() => import('../pages/Skills'));
const AboutMe= lazy(() => import('../pages/AboutMe'));
const Portfolio= lazy(() => import('../pages/Portfolio'));
const ContactMe= lazy(() => import('../pages/ContactMe'));




const Nav = ({dark,setPage,menuBurger,setMenuBurger}) => {


    

    const openMenu = () =>{
        setMenuBurger(!menuBurger)
        const navBurger = document.getElementById('nav')
        if(menuBurger){
            navBurger.style.animation= 'openMenu 1s';
        }else{
        }
    }

    

    return ( 
        
        <nav className={menuBurger ? 'nav-burger' : 'nav'} id='nav'>
            <svg 
            onClick={() => openMenu()}
            xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-menu-2" width="44" height="44" viewBox="0 0 24 24" stroke-width="1.5" stroke="#393E46" fill="none" stroke-linecap="round" stroke-linejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                <line x1="4" y1="6" x2="20" y2="6" />
                <line x1="4" y1="12" x2="20" y2="12" />
                <line x1="4" y1="18" x2="20" y2="18" />
            </svg>
            <p style={dark ? {color:'var(--color4)'}:{color:'var(--color1)',opacity:1}} onClick={() => {setMenuBurger(!menuBurger);setPage(<Suspense fallback={<Loader1/>}><AboutMe/></Suspense>)}} >ABOUT ME</p>
            <p style={dark ? {color:'var(--color4)'}:{color:'var(--color1)',opacity:1}} onClick={() => {setMenuBurger(!menuBurger);setPage(<Suspense fallback={<Loader1/>}><Skills/></Suspense>)}}>SKILLS</p>
            <p style={dark ? {color:'var(--color4)'}:{color:'var(--color1)',opacity:1}} onClick={() => {setMenuBurger(!menuBurger);setPage(<Suspense fallback={<Loader1/>}><Portfolio/></Suspense>)}}>PORTFOLIO</p>
            <p style={dark ? {color:'var(--color4)'}:{color:'var(--color1)',opacity:1}} onClick={() => {setMenuBurger(!menuBurger);setPage(<Suspense fallback={<Loader1/>}><ContactMe/></Suspense>)}} >CONTACT ME</p>
        </nav>
     );
}
 
export default Nav;