import React, { useState} from 'react';
//Source
import LogoDark from '../../source/img/whiteLogo.png';
import ImgBuild from '../../source/img/build.png'
//Components
import Nav from '../ui/Nav';
import SocialMedia from '../ui/SocialMedia'; 
import ButtonDark from '../ui/ButtonDark';

import TitleName from '../ui/TitleName';
// const TitleName = lazy(() => import('../ui/TitleName'));
    

const BuildState = () => {
    

    return ( 
        <div className='container-build'>
            <img src={LogoDark } alt='logo' className='logo' onClick={() => window.location.reload()}/>
            <SocialMedia/>
            <img src={ImgBuild} alt='build' className='build-img' />
            <h1>In maintenance...</h1>

         
        </div>
     );
}
 
export default BuildState;