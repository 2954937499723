import React from 'react';
import TextReveal from './TextReveal';
import Face from '../../source/img/face.png'

const TitleName = ({dark}) => {
    return ( 
        <>
        <div className='container-title'>
            <p style={dark ? {color:'var(--color4)'}:{color:'#393E46',opacity:1}}>I´m</p>
            <h1 style={dark  ? {color:'var(--color4)'}:{color:'var(--color1)'}}>BRUNO</h1>
            {<TextReveal
                dark={dark}
            />  }
            

        </div>
            <img src={Face} alt='face'  className='face'/>
        </>
     );
}
 
export default TitleName;