import React from 'react'
import '../../css/loader1.css'
const Loader1 = () => {
    return ( 
        <div className='container-loader'>
            <span class="loader"></span>
        </div>
     );
}
 
export default Loader1;