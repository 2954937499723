import React, { useEffect, useState,lazy, Suspense, Fragment} from 'react';
import './css/index.css';
import BuildState from './components/pages/BuildState';
//pages
// import Home from './components/pages/Home';
import Loader2 from './components/ui/Loader2';
const Home = lazy(() => import('./components/pages/Home'));

function App() {

  // const [dark, setDark] = useState(true);
  // useEffect(() => {
  //   let bg = document.getElementById('root');
  //   if(dark) bg.style.background = 'var(--gradiantDark)';
  //   else bg.style.background = 'var(--gradiant)';

  // }, [dark])
  
  // return (
  //    <Suspense 
  //     fallback={<Loader2/>}
  //   >
  //     <Home 
  //       dark={dark}
  //       setDark={setDark}
  //     /> 
  //   </Suspense> 
  // );

  return(
    <>
    <BuildState/>
    </>

  );
}

export default App;

